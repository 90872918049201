<template>
  <div class="page-content">
    <page-breadcrumb title="Native11 teachers" class="mb-2" />

    <data-table-ssr id="teacher_list" ref="teacher_list"
      :create-row-fn="createTeacher" :create_schema="teacher_create_schema"
      :get-list-fn="getTeacherList" :columns="teacher_fields"
      :delete-row-fn="removeTeacher"
    />
  </div>
</template>

<script>
import { removeEmpty } from '@core/utils/index'
import service from '../service'
const teacher_create_schema = [
  {
    cols: 12,
    fields: [
      { label: 'Email', field: 'email', input_type: 'text', validate: { required: true }, },
    ]
  }
];

const teacher_fields = [
  { label: 'Email', field: 'email', filter: true,
    filterOptions: { enabled: true, placeholder: '---' },
  },
  { label: 'Created At', field: 'createdAt', input_type: 'date' },
];

export default {
  data(){
    return{
      teacher_create_schema,
      teacher_fields,
      total: 0,
      editing_data: null,
    }
  },
  methods:{
    async createTeacher({ email }) {
      let new_item = await service.create({ email });
      return new_item;
    },
    async getTeacherList({ limit, page, query }) {
      query = removeEmpty(query);
      if (query.email) {
        query.email = { $regex: `^${query.email}` };
      }
      let response = await service.getList({
        query: encodeURIComponent(JSON.stringify(query)),
        page,
        limit,
      });
      let list = []; let total = 0;
      if (response.data.type === "DATA") {
        list = response.data.data.list;
        total = response.data.data.total;
      }
      return { list, total }
    },
    async removeTeacher({ _id }) {
      await service.remove({ id: _id });
      this.$refs.teacher_list.getList();
    },
  }
}
</script>
