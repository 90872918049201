import api from '@/libs/axios'


export default {
  async getList(params) {
    let response = await api.get('/admin/users/teachers', { params })
    return response
  },
  async create(payload) {
    const response = await api.post('/admin/users/create-teacher', payload)
    return response.data.data.user || null
  },
  async remove(payload) {
    const response = await api.post('/admin/users/remove-teacher', payload)
    return response.data || null
  },
}
